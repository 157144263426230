import {createApp} from 'vue';
import App from './App.vue';
import {createRouter, createWebHistory} from 'vue-router';
import './styles.css';

import LandingPage from "./components/LandingPage.vue";

const routes = [
    {
        path: '/',
        component: LandingPage,
        meta: {
            title: 'Convicto - We solve Complex Business Processes with Software | Driven by People, Powered by Conviction'
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Convicto - We solve Complex Business Processes with Software | Driven by People, Powered by Conviction';
    }

    // Create a script element
    const trackingScript = document.createElement('script');
    trackingScript.defer = true;
    trackingScript.dataset.domain = 'convicto.nl';
    trackingScript.src = 'https://analytics.caroga.net/js/script.js';

    // Append the script to the document's head
    document.head.appendChild(trackingScript);

    next();
});


const app = createApp(App);

app.use(router); // Use the router

app.mount('#app');